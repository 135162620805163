import React from 'react';
import { Button } from '@material-ui/core';
import { __ } from '../../common/utils/LanguageHelper';

export default function FullButton({
    color,
    styles,
    content,
    variant,
    noShadow,
    ...rest
}) {
    const fulled = {
        minWidth: 150,
        maxWidth: 300,
        paddingRight: 25,
        paddingLeft: 25,
        height: '53px',
        backgroundColor: '#5268db',
        boxShadow: noShadow
            ? 'none'
            : '0px 9px 20px -5px rgb(82 104 219 / 57%)',
        color: '#fff',
        fontSize: '16px',
        backgroundImage:
            'linear-gradient( 31deg, rgba(215,178,233,0.4) 0%, rgba(83,105,220,0.4) 100%)',
    };

    const outline = {
        minWidth: 150,
        maxWidth: 300,
        paddingRight: 25,
        paddingLeft: 25,
        border: `1px solid ${color || '#586bdc'}`,
        color: color || '#586bdc',
        fontSize: '18px',
        height: '53px',
    };

    return (
        <div
            style={{
                justifyContent: 'center',
                margin: __('0 0 0 15px', '0 15px 0 0'),
                alignItems: 'center',
            }}
        >
            <Button {...rest} style={variant === 'outline' ? outline : fulled}>
                {content}
            </Button>
        </div>
    );
}
